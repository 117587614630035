<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item :to="{ name: 'CustomerList', }" >會員管理</b-breadcrumb-item >
            <b-breadcrumb-item active>會員列表</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <h4 class="mb-2 mb-xl-0 mr-2 font-weight-bold">會員列表</h4>
      <div class="row my-3">
        <div class="col-12 col-xl-6">
          <b-form inline>
            <b-form-select
              v-model="selectedMerchantID"
              :options="merchants"
              @change="handleChangeMerchant"
              :disabled="merchants.length <= 1"
            ></b-form-select>
            <b-form-select
              v-if="merchants.length > 1"
              v-model="isBinded"
              :options="bindedOptions"
              @change="handleChangeMerchant"
              :disabled="selectedMerchantID == 'cross'"
              class="ml-2"
            ></b-form-select>
          </b-form>
        </div>
        <!-- <div class="col-12 col-xl-3"></div> -->
        <div
          class="
            col-12 col-xl-6
            d-flex
            flex-column flex-xl-row
            align-items-start align-items-xl-center
          "
        >
          <b-button
            v-if="$permissions.has($permissions.consts.CUSTOMER_LIST_IMPORT)"
            variant="primary"
            :to="{ name: 'CustomerListImport' }"
          >
            顧客會員資料上傳
          </b-button>
          <b-button class="flex-shrink-0 mb-2 ml-2" variant="primary" @click="exportCustomers">匯出結果</b-button>
          <b-input-group class="mb-2 mb-xl-0 ml-0 ml-xl-4">
            <b-form-input v-model="form.keyword" @keyup.enter="getCustomerList" placeholder="搜尋姓名、手機 email、平台id(outer_code)"></b-form-input>
            <b-input-group-append>
              <b-button @click="getCustomerList" ><i class="fa fa-search"></i></b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-table
            striped
            hover
            responsive
            :items="customers"
            :fields="fields"
            :busy="isFetchCustomerList"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template #cell(avatar_url)="data">
              <div class="d-flex align-items-center justify-content-center">
                <router-link :to="{ name: 'CustomerDetailView', params: { customerID: data.item.id } }" >
                  <b-avatar
                    :src="data.item.avatar_url"
                    variant="secondary"
                    size="2rem"
                  ></b-avatar>
                </router-link>
              </div>
            </template>
            <template #cell(name)="data">
              <router-link :to="{ name: 'CustomerDetailView', params: { customerID: data.item.id } }" >
                {{ hiddenString(data.item.name, 20) || '(無法辨識)' }}
              </router-link>
            </template>
            <template #cell(merchant_types)="data">
              <template v-if="selectedMerchantID == 'cross'">
                <b-badge v-if="data.item.merchant_type_name" variant="primary" class="mr-1">{{ `${data.item.merchant_type_name.name} (${data.item.merchant_type_name.type_name})` }}</b-badge>
                <div v-for="merchant in data.item.cross_merchants" :key="merchant.id">
                  <br />
                  <b-badge variant="primary" class="mr-1">{{ `${merchant.merchant_name || '未知'} (${merchant.merchant_type_name || '未知'})` }}</b-badge>
                </div>
              </template>
              <template v-else>
                <b-badge v-if="data.item.merchant_type_name" variant="primary" class="mr-1">{{ `${data.item.merchant_type_name.name} (${data.item.merchant_type_name.type_name})` }}</b-badge>
                <b-badge v-if="data.item.cross_merchants.length" variant="primary" :id="`cross-merchants-${data.item.id}`">
                  其他通路
                </b-badge>
                <b-popover :target="`cross-merchants-${data.item.id}`" triggers="hover focus click">
                  <div class="text-left">
                    <ul class="my-0">
                      <li v-for="merchant in data.item.cross_merchants" :key="merchant.id">{{ `${merchant.merchant_name} (${merchant.merchant_type_name})` }}</li>
                    </ul>
                  </div>
                </b-popover>
              </template>
            </template>
            <template #cell(actions)="data">
              <b-dropdown variant="secondary">
                <template slot="button-content">
                  <i class="mdi mdi-menu"></i>
                </template>
                <b-dropdown-item :to="{ name: 'CustomerDetailView', params: { customerID: data.item.id } }">
                  檢視
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </div>
      </div>

      <div class="d-flex justify-content-center" style="margin-top: 80px">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
        ></b-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import store from "@/store";
import { BAvatar } from "bootstrap-vue";
import customerApi from "../../../apis/customer";
import merchantApi from "../../../apis/merchant";
import moment from "moment";
import axios from "axios"

import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
  components: { BAvatar },

  data() {
    const baseFields = [
        { key: "avatar_url", label: "" },
        { key: "name", label: "LINE 名稱" },
        { key: "outer_code", label: "平台ID" },
        { key: "mobile_phone", label: "手機" },
        { key: "email", label: "Email" },
        {
          key: "birthday",
          label: "生日",
          formatter: (value) => {
            return value ? moment(value).format('YYYY-MM-DD') : '';
          }
        },
        { key: "merchant_types", label: "來源通路" },
    ];

    return {
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      isFetchCustomerList: false,
      selectedMerchantID: 'cross',
      isBinded: null,
      bindedOptions: [
        { value: null, text: '無論會員有無綁定' },
        { value: true, text: '已綁定' },
        { value: false, text: '未綁定' },
      ],
      merchants: [],
      form: {
        keyword: null,
      },
      customers: [],
      baseFields: baseFields,
      fields: baseFields,
    };
  },

  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
  },

  watch: {
    currentPage() {
      this.getCustomerList();
    }
  },

  created() {
    this.init();
  },

  methods: {
    async init() {
      await this.getMerchants();
      await this.getCustomerList();
    },
    hiddenString(value, length) {
      value = value || ''
      const splittedValue = [...value]
      if (splittedValue.length <= length) {
        return value
      }
      return splittedValue.slice(0, length).join('') + '...'
    },
    async getMerchants() {
      const { data } = await merchantApi.list();
      data.data.forEach((merchant) => {
          if (merchant.is_enabled) {
            this.merchants.push({
              value: merchant.id,
              text: merchant.type_name
                ? `${merchant.name} (${merchant.type_name})`
                : merchant.name,
            })
          }
        })
      console.log(this.merchants);
      if (this.merchants.length > 1) {
        this.merchants.unshift(
          { value: null, text: "全部" }
        )
        this.merchants.unshift(
          { value: 'cross', text: "跨通路" },
        )
      } else {
        this.selectedMerchantID = this.merchants[0].value
      }
    },
    async getCustomerList() {
      try {
        this.isFetchCustomerList = true;
        const { data } = await customerApi.listV2({
          merchant_hashid: this.selectedMerchantID == 'cross' ? null : this.selectedMerchantID,
          show_cross_merchant: this.selectedMerchantID == 'cross' ? true : null,
          is_binded: this.isBinded,
          keyword: this.form.keyword,
          per_page: this.perPage,
          page: this.currentPage,
        });
        this.fields = this.baseFields.concat(this.normalizeProfileFeilds(data.meta.profile_mappings || [])).concat({ key: "actions", label: "操作" });
        this.customers = data.data
        this.totalRows = data.meta.total;
      } catch (e) {
        console.log(e);
      }
      this.isFetchCustomerList = false;
    },
    prepareQuery() {
    },
    normalizeProfileFeilds(profileMappings) {
      return profileMappings.map(mapping => {
        return {
          key: `profiles.${mapping.key}`,
          label: mapping.display_name,
        }
      });
    },
    handleChangeMerchant() {
      if (this.selectedMerchantID == 'cross') {
        this.isBinded = null
      }

      this.getCustomerList();
    },
    exportCustomers() {
      let url = `${process.env.VUE_APP_API_BASE_URL}/admin/organizations/${this.organization.id}` +
        `/customers/export?token=${store.state.auth.token}`;
      if (this.selectedMerchantID && this.selectedMerchantID != 'cross') url = url + "&merchant_hashid=" + this.selectedMerchantID;
      if (this.selectedMerchantID && this.selectedMerchantID == 'cross') url = url + "&show_cross_merchant=" + true;
      if (this.keyword) url = url + "&keyword=" + this.keyword;
      if (this.isBinded) url = url + "&is_binded=" + this.isBinded;

      axios.get(url);

      this.$swal({
        title: '成功',
        text: "請至資料匯出查看結果",
        type: 'success',
        showCancelButton: true,
        confirmButtonText: '前往查看',
        cancelButtonText: '確認'
      }).then((result)=>{
          if (result.value) {
            this.$router.push({
              name: "MyConsoleDownloadFileList",
              params: { org_code: this.$route.params.org_code },
            });
          } else {
            return;
          }
        });
    },
  },
};
</script>

<style></style>
